import * as math from 'mathjs'

// 加
export const add = (num1: number, num2: number): number => {
  return Number(math.add(math.bignumber(num1), math.bignumber(num2)))
}

// 减
export const subtract = (num1: number, num2: number): any => {
  return Number(math.subtract(math.bignumber(num1), math.bignumber(num2)))
}

// 乘
export const multiply = (num1: number, num2: number): any => {
  return Number(math.multiply(math.bignumber(num1), math.bignumber(num2)))
}

// 除
export const divide = (num1: number, num2: number): any => {
  return Number(math.divide(math.bignumber(num1), math.bignumber(num2)))
}
